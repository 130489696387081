.info {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-evenly;
    gap: 2rem;
}

.info .left-section{
    max-width: 500px;
}
.info .right-section{
    max-width: 500px;
}