:root{
  font-family: 'Great Vibes', cursive;
}

* {
  box-sizing: border-box;
  font-family: 'Figtree', sans-serif;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Figtree', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

.styled-text{
  font-family: 'melinda_scriptregular';
}
