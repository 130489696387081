.App {
  text-align: center;
}

main {
  max-width: 600px;
  margin: auto;
  background: #F5F5F5;
  box-shadow: 0px 0px 34px -14px rgba(21,21,21,0.52);
}

section {
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
}

.hero-section{
  background-position: center;
  background-repeat: none;
  background-size: cover;
  position: relative;
}


h1, h2, p{
  color: #1e1e1e;
}

p {
  font-size: 1.1rem;
}

.cover {
  background-color: #55555522;
  width: 100%;
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cover h1 {
  color: white;
  font-weight: 400;
  font-size: 5rem;
  text-align: center;
}

.cover p {
  color: white;
  font-weight: 400;
  font-size: 1rem;
  margin: 1rem;
}

section.white-background {
  background-color: white;
}

section.spacing{
  padding-top: 0.5rem;
}

.typeform{
  width: 100%;
  max-width: 1080px;
  margin: auto;
  height: 300px;
}

.wishes{
  font-size: 3rem;
  font-weight: 400;
  position: relative;
  z-index: 100;
}

#rsvp{
  margin-top: 2rem;
}

.frame-left{
  position: absolute;
  left: 0; bottom: -40px;
}
.frame-right{
  position: absolute;
  right: 0; bottom: 0;
}

.section-divider{
  margin-top: 2rem;
  height: 0.75rem;
  width: auto;
}

@media only screen and (max-width: 500px) {
  section {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}