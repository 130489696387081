.wedding-info{
    display: grid;
    grid-template-columns: [location] 1fr [divider] min-content [buses] 1fr;
    text-align: center;
}

.wedding-info p {
    margin: 0;
    line-height: 1.8rem;
}

.wedding-info p a{
    text-decoration: underline;
    color: inherit;
}

.wedding-info .location{
    grid-column-start: location;
    grid-column-end: location;
}

.wedding-info .divider{
    grid-column-start: divider;
    grid-column-end: divider;
    width: 1px;
    height: 100%;
    background-color: #4f4f4f;
}

.wedding-info .buses{
    grid-column-start: buses;
    grid-column-end: buses;
}