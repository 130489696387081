.rsvp{
    display: grid;
    grid-template-columns: [liner] 1fr [confirmation] min-content [liner2] 1fr;
    align-items: center;
}

.rsvp .liner{
    grid-column-start: liner;
    grid-column-end: liner;
    background-color: #4f4f4f;
    height: 1px;
    width: 100%;
}

.rsvp .liner2{
    grid-column-start: liner2;
    grid-column-end: liner2;
    background-color: #4f4f4f;
    height: 1px;
    width: 100%;
}

.rsvp .confirmation{
    grid-column-start: confirmation;
    grid-column-end: confirmation;
    text-decoration: none;
}

.rsvp .confirmation p{
    white-space: nowrap;
    padding: 1rem;
    margin: 0;
    font-weight: bold;
}