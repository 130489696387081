.date-banner{
    display: grid;
    grid-template-columns: [weekday] 2fr [full-date] 1fr [time] 2fr;
    align-items: center;
}

.date-banner p {
    border-width: 1px;
    border-style: solid;
    border-color: #4f4f4f;
    border-left: none;
    border-right: none;
    line-height: 3rem;
}

.date-banner .weekday{
    grid-column-start: weekday;
    grid-column-end: weekday;
}
.date-banner .full-date{
    grid-column-start: full-date;
    grid-column-end: full-date;
}

.date-banner .full-date p{
    line-height: 3rem;
    margin: 0;
    border: none;
}
.date-banner .full-date p:nth-child(2){
    font-size: 3rem;
}

.date-banner .time{
    grid-column-start: time;
    grid-column-end: time;
}