nav {
    background-color: white;
    padding: 1rem;
}

h1 {
    font-size: 3rem;
    font-weight: 200;
}

span {
    margin-right: 1rem;
    font-family: 'Oswald', sans-serif;
}