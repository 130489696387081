.letter {
    max-width: 600px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    background-color: white;
    position: relative;
}

.letter .header{

}

.letter .body {
    max-width: 360px;
    margin: auto;
}

.letter .divider{
    width: 100%;
    max-width: 100px;
    background-color: rgba(1,1,1,0.5);
    height: 1px;
    margin: auto;
}

.letter img {
    width: 200px;
    height: auto;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translate(-50%,-50%)
}

.letter p, .letter h1{
}